import React from 'react';
import SEO from '../components/seo';
import LayoutPortfolio from '../containers/layout-portfolio';
import ActionGallery from '../components/gallery/actionGallery';

const ActionPage = () => {

    return (
        <LayoutPortfolio>
            <SEO title="Action Photos" description="Lifestyle photos by Anatole Tuzlak"/>
            <ActionGallery/>
        </LayoutPortfolio>
    );
};

export default ActionPage;
